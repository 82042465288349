import { IconLibraries } from "@src/enums/iconLibraries";
import * as bootstrapIcons from "react-icons/bs";
import * as featherIcons from "react-feather";
import * as githubOcticonsIcons from "react-icons/go"
//import * as ionicons5Icons from "react-icons/io5"
import * as tablerIcons from "react-icons/tb"
import * as typIcons from "react-icons/ti"
import React from "react";

// export type BootstrapIconNames = keyof typeof bootstrapIcons;
// export type FeatherIconNames = keyof typeof featherIcons;
// export type GithubOcticonsIconNames = keyof typeof githubOcticonsIcons;
// export type TablerIconNames = keyof typeof tablerIcons;
// export type TypIconNames = keyof typeof typIcons;

export type IconProps = {
  icon: string,
  library: IconLibraries
} & featherIcons.IconProps;

const Icon = ({ icon, library, ...rest}: IconProps) => {
  let IconComponent;

  switch (library) {
    case IconLibraries.Bootstrap:
      IconComponent = bootstrapIcons[icon] || null;
      break;
    case IconLibraries.Feather:
      IconComponent = featherIcons[icon] || null;
      break;
    case IconLibraries.GithubOcticonsIcons:
      IconComponent = githubOcticonsIcons[icon] || null;
      break;
    // case IconLibraries.Ionicons5:
    //   IconComponent = ionicons5Icons[icon];
    //   break;
    case IconLibraries.Tabler:
      IconComponent = tablerIcons[icon] || null;
      break;    
    case IconLibraries.Typicons:
      IconComponent = typIcons[icon] || null;
      break;
    default:
      return <></>
  }

  return IconComponent ? <IconComponent {...rest} /> : <></>

};

export default Icon;